import Siema from 'siema';
import SimpleLightbox from 'simple-lightbox';
const bodyScrollLock = require('body-scroll-lock');
import { cardsEndpoint, initSearchForm } from "../util/search";

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('common');

		const mainMenu = document.querySelector(".menu__items--top");
		const mobileMenu = document.querySelector(".menu__items--vertical");
		const menuProductsLink = Array.from(mainMenu.querySelectorAll("a")).find(item =>
			item.innerHTML.toLowerCase().includes("products") ||
			item.innerHTML.toLowerCase().includes("produkty")
		).parentElement;

		const menuArrow = `<svg class="products__icon products__icon--menu" width="11px" height="7px"><path fill-rule="evenodd" fill="rgb(107, 181, 52)" d="M10.449,1.499 L5.499,6.449 L0.549,1.499 L1.257,0.792 L5.499,5.035 L9.741,0.792 L10.449,1.499 Z"/></svg>`;

		if (menuProductsLink.children[0].nextElementSibling) {
			menuProductsLink.classList.add("menu__item--products");
			menuProductsLink.children[0].insertAdjacentHTML("beforeend", menuArrow);
		}

		const removeState = () => {
			document.querySelectorAll(".products .menu__link--active").forEach(item => item.classList.remove("menu__link--active"));

			document.querySelectorAll(".products__icon--animate").forEach(item => item.classList.remove("products__icon--animate"));

			document.querySelectorAll(".products__dropdown--is-open").forEach((item) => {
				item.classList.remove("products__dropdown--is-open");
				item.classList.remove("products__dropdown--overflow");
				item.style.height = null;
			});
		}

		menuProductsLink.addEventListener("mouseleave", removeState);

		const productLinks = document.querySelectorAll(".products .menu__link");

		productLinks.forEach((link) => {

			const productsArrow = `<svg class="products__icon" width="11px" height="7px"><path fill-rule="evenodd" fill="rgb(107, 181, 52)" d="M10.449,1.499 L5.499,6.449 L0.549,1.499 L1.257,0.792 L5.499,5.035 L9.741,0.792 L10.449,1.499 Z"/></svg>`;

			if (link.nextElementSibling) {
				link.insertAdjacentHTML("beforeend", productsArrow);
			}

			link.addEventListener("click", (e) => {

				if (link.nextElementSibling) {
					e.preventDefault();
				}

				if (e.currentTarget.nextElementSibling.classList.contains("products__dropdown--is-open")) {
					e.currentTarget.classList.remove("menu__link--active");
					e.currentTarget.children[0].classList.remove("products__icon--animate");
					e.currentTarget.nextElementSibling.style.height = null;
					e.currentTarget.nextElementSibling.style.marginBottom = 0;
					e.currentTarget.nextElementSibling.classList.toggle("products__dropdown--is-open");
				} else {

					removeState();

					e.currentTarget.classList.add("menu__link--active");
					e.currentTarget.children[0].classList.toggle("products__icon--animate");

					if (e.currentTarget.nextElementSibling.style.height) {
						e.currentTarget.nextElementSibling.style.height = null;
						e.currentTarget.nextElementSibling.classList.toggle("products__dropdown--is-open");
					} else {
						e.currentTarget.nextElementSibling.style.height = `${e.currentTarget.nextElementSibling.scrollHeight}px`;
						e.currentTarget.nextElementSibling.classList.toggle("products__dropdown--is-open");
					}
				}

				if (window.innerHeight < 1000 && window.innerWidth > 768) {
					if (e.currentTarget.nextElementSibling.scrollHeight > 250) {
						e.currentTarget.nextElementSibling.classList.add("products__dropdown--overflow");
					}
				} else if (window.innerWidth < 768) {
					if (e.currentTarget.nextElementSibling.scrollHeight > 150) {
						e.currentTarget.nextElementSibling.classList.add("products__dropdown--overflow");
					}
				}
			});
		});

		/* Menu rozwijane (mobile) */

		const products = document.querySelector(".menu--fullpage .products");
		const targetElement = document.body;

		if (window.innerWidth <= 768) {

			const menuArrow = `<svg class="products__icon products__icon--mobile" width="11px" height="7px"><path fill-rule="evenodd" fill="rgb(107, 181, 52)" d="M10.449,1.499 L5.499,6.449 L0.549,1.499 L1.257,0.792 L5.499,5.035 L9.741,0.792 L10.449,1.499 Z"/></svg>`;
			const backBtn = `<button class="btn products__back"><svg width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"/></svg> ${document.querySelector("html").getAttribute("lang") === "pl-PL" ? "Wróć" : "Back"}</button>`;
			const mobileMenuProductsLink = Array.from(mobileMenu.querySelectorAll("a")).find(item =>
				item.innerHTML.toLowerCase().includes("products") ||
				item.innerHTML.toLowerCase().includes("produkty")
			);
			const mobileMenuProductsLinkParent = mobileMenuProductsLink.parentElement;
			console.log(mobileMenu.children[3], mobileMenuProductsLink);

			mobileMenuProductsLink.addEventListener("click", (e) => {
				e.preventDefault();
				bodyScrollLock.disableBodyScroll(targetElement);
				targetElement.style.height = "100vh";
				products.classList.add("products--is-open");
			});

			if (mobileMenuProductsLink.nextElementSibling) {
				mobileMenuProductsLinkParent.classList.add("menu__item--products", "menu__item--mobile");
				mobileMenuProductsLinkParent.children[1].insertAdjacentHTML("beforeend", backBtn);
				mobileMenuProductsLinkParent.children[0].insertAdjacentHTML("beforeend", menuArrow);

				document.querySelector(".products__back").addEventListener("click", () => {
					products.classList.remove("products--is-open");
					bodyScrollLock.enableBodyScroll(targetElement);
					targetElement.style.height = "auto";
					removeState();
				});
			}
		}

		initSearchForm(cardsEndpoint);

		const body = document.body.classList
		const breadcrumbLink = document.querySelector(".breadcrumbs__link");

		if (body.contains("page-id-3") || body.contains("page-id-5479") || body.contains("blog") || body.contains("single") || body.contains("tag") || body.contains("tax-Kategorie") || body.contains("tax-Tagi")) {
			breadcrumbLink.classList.add("breadcrumbs__link--variant");
		}

		const documentHeight = Math.max(
			document.body.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.clientHeight,
			document.documentElement.scrollHeight,
			document.documentElement.offsetHeight
        );

		window.addEventListener('scroll', function(event) {
			if (window.scrollY >= documentHeight - window.innerHeight) {
				document.querySelectorAll('.cm-widget')[0].classList.add('scroll-bottom');
			} else {
				document.querySelectorAll('.cm-widget')[0].classList.remove('scroll-bottom');
			}
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

/* Dynamiczne dostosowanie wysokości */

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
});

/* Nawigacja */

const menuBtn = document.querySelector(".btn__menu");

const openMenu = () => {
	const fullPageMenu = document.querySelector(".menu--fullpage");
	const searchContainer = document.querySelector(".search--full");

	menuBtn.classList.toggle("btn__menu--animate");
	document.querySelector(".menu__items--top").classList.toggle("menu__items--hidden");
	document.querySelector(".logo__image").classList.toggle("logo__image--fullpage");
	document.querySelector(".menu").classList.toggle("menu--alternative");
	document.querySelector(".menu").classList.toggle("menu--color");
	document.querySelector(".lang").classList.toggle("lang--hidden");
	fullPageMenu.classList.toggle("menu--is-open");

	if (!fullPageMenu.classList.contains("menu--is-open")) {
		searchContainer.classList.remove("search--animate");
	}
};

menuBtn.addEventListener("click", openMenu);

if (window.innerWidth < 768) {
	const mobileMenu = document.querySelector(".menu__items--vertical");
	const files = document.querySelector(".files--desktop");

	mobileMenu.appendChild(files);

	const searchInput = document.querySelector(".search--full");
	// mobileMenu.appendChild(searchInput);

	mobileMenu.insertBefore(searchInput, mobileMenu.children[0]);
}



window.onscroll = () => {

	const menu = document.querySelector(".menu");
	const menuLogo = document.querySelector(".logo__image");

	if (window.scrollY >= 50) {
		menu.classList.add("menu--fixed");
		menuLogo.classList.add("logo__image--color");
	} else {
		
		if (document.body.classList.contains("blog") || document.body.classList.contains("single-oferty-pracy") || document.body.classList.contains("single-post")) {
			menuLogo.classList.add("logo__image--dark");
		}
		
		if (!document.body.classList.contains("tax-Kategorie")) {
			menu.classList.remove("menu--fixed");
			menuLogo.classList.remove("logo__image--color");
		} else {
			menu.classList.remove("menu--fixed");
		}
	}
};

/* Animacja ładująca */

document.addEventListener("DOMContentLoaded", () => {
	const body = document.querySelector("body");
	body.classList.add("loaded");
});

/* Dostosowanie szerokości pseudoelementów względem szerokości ekranu */

const screenWidth = screen.width;

document.documentElement.style.setProperty("--screenWidth", `${screenWidth * 2}px`);

/* Nadanie klasy */

const setClassName = () => {
	const menuItems = Array.prototype.slice.call(document.querySelectorAll(".container--header .menu__item"));
	let lastElement = menuItems.length - 1;

	if (menuItems[lastElement].textContent === "B2B") {
		menuItems[lastElement].classList.add("menu__item--b2b");
	}
};

setClassName();

/* Logotypy */

if (!document.querySelector("body").classList.contains("error404")) {
	const footerSlider = new Siema({
		selector: "#footerSlider",
		duration: 200,
		easing: "ease-out",
		perPage: {
			320: 2,
			768: 3,
			960: 4,
			1024: 5
		},
		startIndex: 0,
		draggable: true,
		multipleDrag: true,
		threshold: 20,
		loop: true,
		rtl: false,
		onInit: function() {},
		onChange: function() {}
	});

	if (window.innerWidth <= 960) {
		setInterval(() => {
			footerSlider.next();
		}, 5000);
	}
}

/* Przycisk kontaktowy */

const cmContactBtn = document.querySelector(".cm-contact");
const cmContactDetails = document.querySelector(".cm-contact__details");
const cmContactIconPhone = document.querySelector(".cm-contact__icon--phone");
const cmContactIconClose = document.querySelector(".cm-contact__icon--close");
const cmContactLink = document.querySelector(".cm-contact__link");

cmContactBtn.addEventListener("click", () => {

	cmContactDetails.classList.toggle("cm-contact__details--active");

	if (cmContactDetails.classList.contains("cm-contact__details--active")) {
		cmContactDetails.previousElementSibling.style.visibility = "hidden";
		cmContactIconPhone.classList.add("cm-contact__icon--disabled");
		cmContactIconClose.classList.remove("cm-contact__icon--disabled");
	} else {
		cmContactDetails.previousElementSibling.style.visibility = null;
		cmContactIconPhone.classList.remove("cm-contact__icon--disabled");
		cmContactIconClose.classList.add("cm-contact__icon--disabled");
	}
});

cmContactLink.addEventListener("click", (e) => {
	e.stopPropagation();
});

/* Ustawianie daty */

const footerDate = document.querySelector(".copyright__date");

const setCopyright = (element) => {
	element.innerHTML = `Trzuskawica S.A. &copy; ${new Date().getFullYear()}`;
};

setCopyright(footerDate);

/* Cookies */

const cookies = document.querySelector(".cookies");

if (!localStorage.cookiesAccepted) {
	cookies.classList.add("cookies--visible");
}

const setCookies = (e) => {
	e.preventDefault();

	localStorage.cookiesAccepted = true;
	cookies.classList.remove("cookies--visible");
};

document.querySelector(".cookies__btn").addEventListener("click", setCookies);

/* Lightbox */

new SimpleLightbox({
	elements: "[data-lightbox]"
});
