import {addMargin, introductionDesc} from "../util/vendors.js";
import "../util/TweenMax";
import gsap from "gsap";
import Siema from "siema";

export default {
	init() {
		// JavaScript to be fired on all pages
		const employeesCount = document.querySelector(".counter__final-score--employees").dataset.score;
		const locationsCount = document.querySelector(".counter__final-score--locations").dataset.score;
		const tonesCount = document.querySelector(".counter__final-score--tones").dataset.score;
		const workersCount = document.querySelector(".counter__final-score--workers").dataset.score;
		const clientsCount = document.querySelector(".counter__final-score--clients").dataset.score;
		// const trainsCount = document.querySelector(".counter__final-score--trains").dataset.score;
		// const lorriesCount = document.querySelector(".counter__final-score--lorries").dataset.score;
		// const loadCount = document.querySelector(".counter__final-score--load").dataset.score;

		if (window.innerWidth > 768) {
			addMargin(introductionDesc);
		}

		Number.prototype.numberFormat = function(decimals, decPoint, thousandsSep) {
			decPoint = typeof dec_point !== "undefined" ? decPoint : ".";
			thousandsSep = typeof thousands_sep !== "undefined" ? thousandsSep : " ";

			let parts = this.toFixed(decimals).split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);

			return parts.join(decPoint);
		};

		const companyDigitsAnimate = () => {
			const counterDigits = document.querySelectorAll(".company__counter");
			let digit1 = { val: 0 }, newVal1 = tonesCount;
			let digit2 = { val: 0 }, newVal2 = workersCount;
			let digit3 = { val: 0 }, newVal3 = clientsCount;

			let counter = { count1: 0, count2: 0, count3: 0 };
			let counterDigit1 = counterDigits[0];
			let counterDigit2 = counterDigits[1];
			let counterDigit3 = counterDigits[2];


			TweenMax.to(counter, 4, {
				count1: tonesCount,
				onUpdate: function () {
					let formatNumber = counter.count1.numberFormat(0);
					counterDigit1.innerHTML = formatNumber;
				},
				ease: Circ.easeOut
			});

			TweenMax.to(counter, 4, {
				count2: workersCount,
				onUpdate: function () {
					let formatNumber = counter.count2.numberFormat(0);
					counterDigit2.innerHTML = formatNumber;
				},
				ease: Circ.easeOut
			});

			TweenMax.to(counter, 4, {
				count3: clientsCount,
				onUpdate: function () {
					let formatNumber = counter.count3.numberFormat(0);
					counterDigit3.innerHTML = formatNumber;
				},
				ease: Circ.easeOut
			});

			gsap.to(digit1, 4, {val: newVal1, roundProps: "val", onUpdate: () => { counterDigits[0].innerHTML = digit1.val; }});

			gsap.to(digit2, 4, {val: newVal2, roundProps: "val", onUpdate: () => { counterDigits[1].innerHTML = digit2.val; }});

			gsap.to(digit3, 4, {val: newVal3, roundProps: "val", onUpdate: () => { counterDigits[2].innerHTML = digit3.val; }});
		};

		const digitsAnimate = () => {
			const counterDigits = document.querySelectorAll(".counter__score");

			let counter = { count1: 0, count2: 0 };
			let counterDigit1 = counterDigits[0];
			let counterDigit2 = counterDigits[1];

			TweenMax.to(counter, 4, {
				count1: employeesCount,
				onUpdate: function () {
					counterDigit1.innerHTML = counter.count1.numberFormat(0);
				},
				ease: Circ.easeOut
			});

			TweenMax.to(counter, 4, {
				count2: locationsCount,
				onUpdate: function () {
					counterDigit2.innerHTML = counter.count2.numberFormat(0);
				},
				ease: Circ.easeOut
			});
		};

		setTimeout(() => {
			if (window.innerWidth > 768) {
				companyDigitsAnimate();
				digitsAnimate();
			}
		}, 1000);

		const companySlider = new Siema({
			selector: "#companySlider",
			duration: 200,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: true,
			multipleDrag: true,
			threshold: 20,
			loop: true,
			rtl: false,
			onInit: function() {},
			onChange: changingClassPillars
		});

		const iconsSlider = new Siema({
			selector: "#iconsSlider",
			duration: 200,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: true,
			multipleDrag: true,
			threshold: 20,
			loop: true,
			rtl: false,
			onInit: function() {},
			onChange: changingClassIcons
		});

		Siema.prototype.homepageSliderDots = function(element) {
			for (let i = 0; i < this.innerElements.length; i++) {
				const navWrapper = document.querySelector(`.${element}`);
				const btn = document.createElement("button");

				btn.classList.add("dots__item");
				btn.addEventListener("click", () => this.goTo(i));

				navWrapper.appendChild(btn);

				const dots = Array.prototype.slice.call(document.querySelectorAll(".dots__item"));
				dots[0].classList.add("dots__item--active");
			}
		};

		function changingClassPillars() {

			const dots = document.querySelectorAll(".dots--pillars .dots__item");

			dots.forEach((slide, i) => {
				let addOrRemove = i === this.currentSlide ? "add" : "remove";
				dots[i].classList[addOrRemove]("dots__item--active");
			});
		};

		function changingClassIcons() {

			const dots = document.querySelectorAll(".dots--icons .dots__item");

			dots.forEach((slide, i) => {
				let addOrRemove = i === this.currentSlide ? "add" : "remove";
				dots[i].classList[addOrRemove]("dots__item--active");
			});
		};

		companySlider.homepageSliderDots("dots--pillars");
		iconsSlider.homepageSliderDots("dots--icons");

		const setNumbers = () => {
			const companyCounters = document.querySelectorAll(".company__counter");
			const counterScore = document.querySelectorAll(".counter__score");

			// companyCounters[1].textContent = `${trainsCount}`;
			// companyCounters[4].textContent = `${lorriesCount}`;
			// companyCounters[5].textContent = `${loadCount}`;
			companyCounters[1].textContent = `${tonesCount}`;
			companyCounters[2].textContent = `${workersCount}`;
			companyCounters[3].textContent = `${clientsCount}`;
			counterScore[0].textContent = `${employeesCount}`;
			counterScore[1].textContent = `${locationsCount}`;
		};

		window.addEventListener("resize", () => {
			if (window.innerWidth < 600) {
				companySlider.init();
			}

			if (window.innerWidth > 600) {
				companySlider.destroy(true);
			}

			if (window.innerWidth < 550) {
				iconsSlider.init();
			}

			if (window.innerWidth > 550) {
				iconsSlider.destroy(true);
			}
		});

		if (window.innerWidth <= 600) {
			setNumbers();
			companySlider.init();
		} else {
			companySlider.destroy(true);
		}

		if (window.innerWidth <= 550) {
			iconsSlider.init();
		} else {
			iconsSlider.destroy(true);
		}

		/* Oś czasu */

		const timelineSlider = new Siema({
			selector: "#timelineSlider",
			duration: 200,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: true,
			multipleDrag: true,
			threshold: 20,
			loop: false,
			rtl: false,
			onInit: function() {},
			onChange: changingClassDots
		});

		Siema.prototype.sliderDots = function(element) {
			for (let i = 0; i < this.innerElements.length; i++) {
				const navWrapper = document.querySelector(`.${element}`);
				const years = Array.prototype.slice.call(document.querySelectorAll(".timeline__years"));
				const btn = document.createElement("button");
				const paragraph = document.createElement("p");
				btn.classList.add("nav__dot");
				paragraph.classList.add("nav__year");
				paragraph.textContent = years[i].textContent;
				btn.setAttribute("data-index", i);
				btn.addEventListener("click", () => this.goTo(i));
				navWrapper.appendChild(btn);
				btn.appendChild(paragraph);

			}
		};

		timelineSlider.sliderDots("nav");

		const timelineDots = new Siema({
			selector: "#timelineDots",
			duration: 200,
			easing: "ease-out",
			perPage: {
				1366: 6,
				1280: 5,
				1024: 4,
				768: 3,
				300: 2
			},
			startIndex: 0,
			draggable: false,
			multipleDrag: false,
			threshold: 20,
			loop: false,
			rtl: false,
			onInit: function() {

				const dots = Array.prototype.slice.call(document.querySelectorAll(".nav__dot"));
				dots[0].classList.add("nav__dot--active");

				dots.forEach((dot) => {
					dot.addEventListener("click", (e) => {
						if (parseInt(e.currentTarget.dataset.index) > this.perPage) {
							this.goTo(parseInt(e.currentTarget.dataset.index) + 1);
						} else {
							this.goTo(parseInt(e.currentTarget.dataset.index) - 1);
						}
					});
				});

			},
			onChange: function() {}
		});

		function changingClassDots() {

			const dots = document.querySelectorAll(".nav__dot");

			dots.forEach((slide) => {
				let index = this.currentSlide;
				let addOrRemove = index === this.currentSlide ? "add" : "remove";
				slide.classList.remove("nav__dot--active");
				dots[index].classList[addOrRemove]("nav__dot--active");

				if (this.currentSlide > timelineDots.perPage) {
					timelineDots.goTo(index);
				} else {
					timelineDots.goTo(index);
				}
			});
		};

		setInterval(() => {
			timelineSlider.next()
		}, 10000);

		const prevSlide = () => {
			timelineSlider.prev();
		};

		const nextSlide = () => {
			timelineSlider.next();
		};

		document.querySelector(".slider__btn--prev").addEventListener("click", prevSlide);
		document.querySelector(".slider__btn--next").addEventListener("click", nextSlide);

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
